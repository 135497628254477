@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Ubuntu:500,700&display=swap");

body {
  font-family: "Lato";
  // background-image: linear-gradient(white, gray, rgb(38, 37, 37), black);
  background: #222;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu";
  color: #fff;
}

p,
label {
  color: #999;
}

a {
  text-decoration: none;
  color: #999;
}

textarea,
input[type="text"],
input[type="password"] {
  font-family: "Lato";
  color: #fff;
  font-size: 18px;
  display: block;
  border: none;
  padding: 10px;
  background: #333;
  max-width: 500px;
  width: 100%;
}

button {
  border: none;
  cursor: pointer;
  padding: 10px 25px;
  font-weight: bold;
  background: #3b5664;
  border-radius: 20px;
  color: #fff;
  margin: 15px 5px;
  font-weight: bold;

  &:disabled {
    opacity: 0.75;
    filter: grayscale(50%);
    cursor: not-allowed;
  }
}

select {
  padding: 10px 25px;
  background: #50a2a7;
  border: none;
  color: #fff;
  border-radius: 20px;
  margin-bottom: 50px;
  font-size: 16px;
}

input[type="text"],
input[type="password"] {
  color: #fff;
  font-size: 16px;
  display: block;
  border: none;
  padding: 10px;
  background: #333;
  max-width: 500px;
  width: 100%;
  margin-top: 5px;
}

.grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
}

.wrapper {
  max-width: 500px;
  margin: 0 auto;
}

// Custom radio buttons from - https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

.input-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  background: #333;
  padding: 10px;
  text-align: center;

  &--sm {
    max-width: 500px;
    font-size: 14px;
    font-weight: bold;
  }
}

/* Hide the browser's default radio button */
.input-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 50%;
  left: 15px;
  height: 25px;
  width: 25px;
  transform: translateY(-50%);
  background-color: #222;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.input-container:hover input ~ .checkmark {
  background-color: #444;
}

/* When the radio button is checked, add a blue background */
.input-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.input-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.input-container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
