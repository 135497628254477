.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #222;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 10px -8px rgba(0, 0, 0, 0.5);
  z-index: 999;

  a {
    color: #fff;
    font-weight: bold;
    padding: 25px;
  }
}

.logo {
  margin-right: auto;
}

.active {
  background: #2f2f2f;
}

@media only screen and (max-width: 900px) {
  .nav {
    display: none;
  }
}
