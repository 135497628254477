@import url("https://fonts.googleapis.com/css?family=Playball&display=swap");

html {
  scroll-behavior: smooth;
}
.block {
  display: flex;
  flex-direction: column;
}
.landingPage {
  background: #fff;

  h2 {
    color: #3b5664;
    margin-bottom: 25px;
    font-size: 38px;
    text-align: center;
    font-family: "Playball";
  }

  h3 {
    color: #3b5664;
    font-size: 18px;
    font-weight: 500;
  }

  a {
    font-size: 18px;
  }

  p {
    font-family: "Lato";
    font-weight: 100;
    line-height: 1.5;
  }

  footer,
  section {
    padding: 100px 50px;
  }
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 999;
  transition: all 0.2s;
  background: darken(#3b5664, 8%);
  border-bottom: none;
  box-shadow: 0 4px 10px rgba(#333, 0.5);

  ul {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    color: #fff;

    li {
      padding: 25px;
      font-family: "Ubuntu";
      font-size: 18px;

      a {
        color: inherit;
      }
    }
  }
}

.navbarMenuIcon {
  display: none;
}
.header {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(rgba(#333, 0.75), rgba(#333, 0.75)),
    url("../../images/hero.jpg");
  // https://www.pexels.com/photo/several-scissors-1319460/
  background-size: cover;
}
.headerContent {
  margin: 0 auto;
  text-align: center;

  img {
    margin: 80px 0 25px;
  }

  p {
    font-size: 18px;
    color: #fff;
    line-height: 1.5;
  }
}

.hours {
  background: #3b5664;
  color: #fff;
  text-align: center;
  padding: 30px;
  letter-spacing: 2px;
  font-family: "Lato";
  font-weight: 100;
  font-size: 14px;
}

.badge {
  background-image: url("../../images/MB_VoteMEBadge.png");
  height: 300px;
  width: 275px;
  background-size: cover;
  position: relative;

  transform-style: preserve-3d;
}

.aboutSection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.about {
  text-align: center;
  max-width: 50%;
  p {
    color: #555;
    max-width: 800px;
    margin: 0 auto;
    font-size: 24px;

    &:last-of-type {
      margin-top: 50px;
    }
  }

  button {
    margin: 25px 0 0;
    font-size: 18px;
  }
}

.gallery {
  position: relative;
  transform-style: preserve-3d;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.galleryImageGrid {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 50px;

  div {
    height: 275px;
    width: 275px;
    background-size: cover;
    position: relative;
    transform-style: preserve-3d;

    &:nth-of-type(1) {
      background-image: url("../../images/the_barber_sharp_exterior.jpg");
    }
    &:nth-of-type(2) {
      background-image: url("../../images/kelly-at-work.jpg");
    }
    &:nth-of-type(3) {
      background-image: url("../../images/kelly_at_work2.jpg");
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 10px;
      left: 10px;
      background: #b42330;
      transform: translateZ(-1px);
    }
  }
}

.galleryBarberImageGrid {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 50px;

  div {
    height: 275px;
    width: 275px;
    background-size: cover;
    position: relative;
    transform-style: preserve-3d;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 10px;
      left: 10px;
      background: #b42330;
      transform: translateZ(-1px);
    }
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    p {
      margin-top: 15px;
      margin-bottom: 10px;

      font-weight: bold;
    }

    &:nth-of-type(1) {
      div {
        background-image: url("../../images/chelsie.png");
      }
    }
    &:nth-of-type(2) {
      div {
        background-image: url("../../images/jesse.jpeg");
      }
    }
    &:nth-of-type(3) {
      div {
        background-image: url("../../images/steph.jpeg");
      }
    }
    &:nth-of-type(4) {
      div {
        background-image: url("../../images/dustin.jpeg");
        background-position: center;
      }
    }
    &:nth-of-type(5) {
      div {
        background-image: url("../../images/kelly_new.jpg");
        background-position: center;
      }
    }
    &:nth-of-type(6) {
      div {
        background-image: url("../../images/ryan.jpg");
        background-position: center;
      }
    }
  }
}
.gallery {
  position: relative;
  transform-style: preserve-3d;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.divider {
  background: #3b5664;
  padding: 75px 25px;
  // https://unsplash.com/photos/NO8Sj4dKE8k
  background-image: linear-gradient(rgba(#333, 0.7), rgba(#333, 0.7)),
    url("../../images/wedding.jpg");
  background-size: cover;
  background-position: center 35%;

  p {
    font-weight: 500;
    color: #fff;
    font-size: 22px;
    text-align: center;
    font-style: italic;
  }
}

.meetTheOwnerText {
  text-align: center;
}

.meetTheOwnerText {
  max-width: 700px;
  margin: 30px auto 0;

  p {
    font-size: 20px;
    margin: 25px 0;
    color: #555;
  }
}

.meetTheOwnerImg {
  display: block;
  margin: 0 auto;
  align-self: flex-start;
  border-radius: 50%;
}

.footer {
  text-align: center;
  background: #3b5664;

  h3 {
    color: #fff;
    font-size: 24px;
  }

  p {
    font-size: 20px;
    margin: 10px 0;
    color: #fff;
    opacity: 0.75;
  }

  small {
    display: block;
    font-size: 13px;
    margin-top: 25px;
    color: #fff;
    opacity: 0.75;
  }
}

.footerSocial {
  margin-top: 50px;
  a {
    margin: 10px;
    height: 45px;
    width: 45px;
    background: rgba(#fff, 0.15);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    svg {
      color: #fff;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .galleryImageGrid {
    flex-direction: column;
    align-items: center;

    div {
      margin: 15px 0;
    }
  }
}

@media only screen and (max-width: 900px) {
  .landingPage {
    footer,
    section {
      padding: 75px 25px;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ul,
    .menu {
      position: fixed;
      flex-direction: column;
      height: auto;
      width: 100%;
      top: 85px;
      background: #fff;
      visibility: hidden;
      box-shadow: 0 4px 10px rgba(#333, 0.5);
      transition: all 0.2s;
      opacity: 0;

      li {
        text-align: center;
        color: #3b5664;
      }
    }
  }

  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
  }
  .aboutSection {
    display: flex;
    flex-direction: column;
  }

  .badge {
    background-image: url("../../images/MB_VoteMEBadge.png");
    height: 300px;
    width: 275px;
    background-size: cover;
    position: relative;

    transform-style: preserve-3d;
  }

  .navbarMenuIcon {
    display: block;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    font-size: 16px;

    svg {
      margin-left: 5px;
    }
  }
  .showMobile {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .meetTheOwnerText {
    flex-direction: column;
  }

  .meetTheOwnerText {
    margin: 0 auto;
    text-align: center;
    max-width: 800px;
    font-size: 24px;

    h2 {
      text-align: center;
    }
  }

  .meetTheOwnerImg {
    margin: 0 auto 50px;
  }
}
