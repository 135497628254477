.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 700px;
  width: 90%;
  height: 75vh;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 4px 10px rgba(#333, 0.5);
  border-radius: 3px;
  z-index: 999;

  ul {
    list-style: none;
    margin: 25px 0;

    li {
      p {
        color: #333;
        // font-weight: 500;

        &:first-child {
          font-weight: 500;
        }

        span {
          background: #f1c40f;
          border-radius: 50px;
          padding: 3px 6px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }

  small {
    display: block;
    margin: 10px 0;
    color: #555;
    font-style: italic;
  }

  hr {
    border: 1px solid #eeeeee;
  }

  &__scrollableContent {
    overflow-y: scroll;
    padding: 50px;
    height: 100%;

    // Width
    &::-webkit-scrollbar {
      width: 10px;
    }

    // Handle
    &::-webkit-scrollbar-thumb {
      background: #ccc;
    }
  }

  &__heading {
    margin-bottom: 50px !important;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }

  &__closeButton {
    position: fixed;
    top: -10px;
    left: -10px;
    height: 30px;
    width: 30px;
    line-height: 22px;
    background: none;
    padding: 5px;
    margin: 0;
    color: #ccc;
    background: #333;
    border: 2px solid #fff;
  }
}

.overlay {
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(#333, 0.5);
}
