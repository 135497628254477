.header {
  height: 50vh;
  background: #2b2b2b;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;

  h1 {
    font-size: 36px;
  }
}

.text {
  text-align: center;
  min-height: 70vh;
  color: #fff;
  padding: 100px 35px 100px;

  h2 {
    margin: 20px;
  }

  p {
    font-size: 18px;
  }

  span {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    height: 40vh;
  }

  .text {
    padding: 25px 25px 100px;
  }
}
